import React from "react";
import AppContext from "../../contexts/AppContext";
import routes from "../../routes";
import api from "../../utils/api";
import Loading from "../components/Loading";
import ImageView from "../components/ImageView";
import {SvgSprite} from "../components/SvgSprite";
import {assetUrl, debounce} from "../../utils/etc";
import i18n from "../../i18n";
import {generatePath} from "react-router";
import Cropper from "react-easy-crop";
import FileChooseButton from "../components/FileChooseButton";
import {Task} from "../../data/Task";
import ErrorModal from "../modals/ErrorModal";
import {logEvent, userEvents} from "../../utils/log";

const PHOTO_FETCH_INTERVAL = 2000;

const cropAspect = {
  square: 1,
  portrait: 0.666666667,
  landscape: 1.5,
};

export default class ProcessingPage extends React.Component {

  state = {
    isLoading: false,
    photo: null,
    creatives: [],
    term: "",
    termTask: null,
    termSuggestions: [],
    termSuggestionsAll: [],
    cropImage: null,
    cropState: {
      aspect: cropAspect.square,
      zoom: 1,
      x: 0,
      y: 0,
    },
    cropResult: null,
    continueAfterOscar: false,
  };

  fetchPhotoTimer = null;
  fetchTaskTimer = null;

  componentDidMount() {
    logEvent(userEvents.PAGE_STEP_2);

    this.fetchSuggestions();
    this.fetchPhoto();
  }

  componentWillUnmount() {
    clearTimeout(this.fetchPhotoTimer);
    clearTimeout(this.fetchTaskTimer);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      clearTimeout(this.fetchPhotoTimer);
      clearTimeout(this.fetchTaskTimer);
      this.setState({continueAfterOscar: true});
      this.fetchPhoto();
    }
  }

  fetchSuggestions = () => {
    api.fetchSuggestions()
      .then((res) => this.setState({termSuggestionsAll: res}))
      .catch((err) => console.error(err));
  };

  fetchPhoto = () => {
    api.fetchPhotoById(this.props.match.params.id)
      .then(this.handleFetchPhotoSuccess)
      .catch(this.handleFetchPhotoError);
  };

  handleFetchPhotoSuccess = (res) => {
    const nextState = {
      photo: res.photo,
      creatives: res.creatives,
    };

    const resultsCreatives = res.creatives.filter((creative) => {
      return creative.alias === "result" && creative.isSelected;
    });

    if (resultsCreatives.length > 0) {
      const processedAmount = resultsCreatives.count((creative) => creative.isProcessed);
      if (processedAmount > 0) {
        logEvent(userEvents.PROCESSING_PROCESSED);
        this.props.history.replace(generatePath(routes.RESULT, {hash: res.photo.hash}));
        return;
      }

      const failedAmount = resultsCreatives.count((creative) => creative.isFailed);
      if (failedAmount === resultsCreatives.length) {
        logEvent(userEvents.PROCESSING_FAILED);

        const creativeWithError = resultsCreatives.find((creative) => !!creative.error);
        const button = <button
          className="btn-select"
          children={i18n.t("select_another_photo")}
          onClick={() => {
            this.context.popModal();
            this.handleFirstFileSelected();
          }} />;

        this.context.pushModal(<ErrorModal
          key="ProcessingPage_handleUpdate_ErrorModal"
          message={creativeWithError && creativeWithError.error}
          buttons={button}
        />);
      }
    } else {
      const oscarCreative = res.creatives.find((creative) => creative.alias === "oscar");
      if (oscarCreative && (oscarCreative.isProcessed || oscarCreative.isFailed) && this.state.continueAfterOscar) {
        this.setState(nextState, this.handleCropConfirm);
        return;
      }
    }

    const creativesInProcessing = res.creatives.count((creative) => creative.isProcessing);
    if (creativesInProcessing > 0) {
      this.fetchPhotoTimer = setTimeout(this.fetchPhoto, PHOTO_FETCH_INTERVAL);
    }

    this.setState(nextState);
  };

  handleFetchPhotoError = (err) => {
    console.error(err);
    this.fetchPhotoTimer = setTimeout(this.fetchPhoto, PHOTO_FETCH_INTERVAL);
  };

  submitTermTask = () => {
    if (this.state.term.length < 3) {
      return;
    }

    // чтобы сразу показать процессинг таска
    this.setState({
      termTask: new Task({id: -1, status: Task.STATUS_PENDING})
    });

    debounce("ProcessingPage_submitTermTask_request", 100, () => {
      api.fetchSuggestionImages(this.state.term)
        .then(this.handleFetchTaskSuccess)
        .catch(this.handleFetchTaskError);
    });
  };

  handleFetchTaskSuccess = (result) => {
    this.setState({
      termTask: new Task({
        id: -1,
        status: Task.STATUS_PROCESSED,
        result: result.filter((image) => image.url.startsWith("https"))
      }),
    });
  };

  handleFetchTaskError = (err) => {
    console.error(err);
    this.setState({
      termTask: new Task({
        id: -1,
        status: Task.STATUS_FAILED,
      }),
    });
  };

  handleImageSelected = (source, imageItem) => {
    logEvent(userEvents.IMAGE_SELECT, {source});

    const image = new Image();
    const imageUrl = (source === "search")
      ? window.appConfig.paths.apiImagesProxy + "/images-proxy?url=" + encodeURIComponent(imageItem.url)
      : imageItem.url;

    image.onload = () => {
      const cropState = {
        x: 0,
        y: 0,
      };

      if (image.naturalWidth === image.naturalHeight) {
        cropState.aspect = cropAspect.square;
      } else if (image.naturalWidth > image.naturalHeight) {
        cropState.aspect = cropAspect.landscape;
      } else {
        cropState.aspect = cropAspect.portrait;
      }

      this.setState({
        isLoading: false,
        cropState,
        cropImage: {
          proxiedUrl: image.src,
          url: imageItem.url,
          width: image.naturalWidth,
          height: image.naturalHeight,
        },
      });
    };

    image.onerror = () => {
      this.setState({isLoading: false});
      this.context.pushModal(<ErrorModal
        key="ProcessingPage_handleImageSelected_ErrorModal"
        message={i18n.t("image_load_failure_modal__message")}
        buttons={<button
          className="btn-select"
          children={i18n.t("image_load_failure_modal__button")}
          onClick={() => this.context.popModal()}
        />}
      />);
    };

    this.setState({isLoading: true}, () => image.src = imageUrl);
  };

  handleCropAreaChangeCompleted = (cropArea, cropAreaInPixels) => {
    this.setState({cropResult: cropAreaInPixels});
  };

  handleCropAreaChanged = (cropArea) => {
    this.setState({cropState: {...this.state.cropState, ...cropArea}});
  };

  handleCropAspectChanged = (aspect) => {
    this.setState({cropState: {...this.state.cropState, aspect}});
  };

  handleCropZoomChanged = (zoom) => {
    this.setState({cropState: {...this.state.cropState, zoom}});
  };

  handleCropConfirm = () => {
    const imageData = {crop: this.state.cropResult};
    const oscarCreative = this.state.creatives.find((creative) => creative.alias === "oscar");

    if (oscarCreative.isProcessed) {
      this.setState({isLoading: true}, () => {
        api.attachImageToPhoto(this.state.photo.id, this.state.cropImage.url, imageData)
          .then((res) => {
            logEvent(userEvents.IMAGE_UPLOADED);
            this.handleFetchPhotoSuccess(res);
          })
          .catch((err) => {
            logEvent(userEvents.IMAGE_UPLOAD_FAILED);
            this.handleFetchPhotoError(err);
          });
      });
    } else if (oscarCreative.isFailed) {
      const button = <FileChooseButton
        className="btn-select"
        children={i18n.t("select_another_photo")}
        onFileSelected={(file) => {
          this.context.popModal();
          this.handleFirstFileSelected(file);
        }} />;

      this.context.pushModal(<ErrorModal
        key="ProcessingPage_handleCropConfirm_ErrorModal"
        message={oscarCreative.error}
        buttons={button}
      />);
    } else {
      this.setState({isLoading: true}, () => {
        setTimeout(this.handleCropConfirm, 1000);
      });
    }
  };

  handleCropCancel = () => {
    this.setState({
      cropImage: null,
      cropResult: null,
    });
  };

  handleTermFieldChanged = (e) => {
    const value = e.target.value;
    const termSuggestions = filterSuggestion(value, this.state.termSuggestionsAll);

    this.setState({term: value, termSuggestions});
  };

  handleTermFieldFocused = () => {
    const value = this.state.term;
    const termSuggestions = filterSuggestion(value, this.state.termSuggestionsAll);

    this.setState({termSuggestions});
  };

  handleTermFieldBlurred = () => {
    setTimeout(() => {
      this.setState({termSuggestions: []});
    }, 200);
  };

  handleTermSuggestionClicked = (suggestion) => {
    api.hitSuggestion(suggestion.term)
      .then(() => {/* ignore */})
      .catch(() => {/* ignore */});

    this.setState({term: suggestion.term, termSuggestions: []}, this.submitTermTask);
  };

  handleTermCancel = () => {
    this.setState({
      term: "",
      termTask: null,
      termSuggestions: [],
    });
  };

  handleFirstFileSelected = (file) => {
    api.createPhoto(file)
      .then((res) => {
        this.props.history.replace(generatePath(routes.PROCESSING, {id: res.photo.id}));
      })
      .catch(this.handleFetchPhotoError);
  };

  handleSecondFileSelected = (file) => {
    if (window.clientConfig.isWebview) {
      this.handleImageSelected("device", {url: file});
    } else {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => this.handleImageSelected("device", {url: reader.result});
      reader.onerror = (err) => console.error(err);
    }
  };

  render() {
    const props = {
      ...this.state,
      onTermSubmit: (e) => {
        e.preventDefault();
        this.submitTermTask();
      },
      onTermFieldChange: this.handleTermFieldChanged,
      onTermFieldFocus: this.handleTermFieldFocused,
      onTermFieldBlur: this.handleTermFieldBlurred,
      onTermSuggestionClick: this.handleTermSuggestionClicked,
      onTermCancelButtonClick: this.handleTermCancel,
      onSecondFileSelected: this.handleSecondFileSelected,
      onImageSelect: (image) => this.handleImageSelected("search", image),
      onCropAreaChangeComplete: this.handleCropAreaChangeCompleted,
      onCropAreaChange: this.handleCropAreaChanged,
      onCropZoomChange: this.handleCropZoomChanged,
      onCropAspectChange: this.handleCropAspectChanged,
      onCropConfirm: () => {
        logEvent(userEvents.IMAGE_CROPPED);
        this.handleCropConfirm();
      },
      onCropCancel: this.handleCropCancel,
    };

    return <ProcessingPageView {...props} />;
  }
}

ProcessingPage.contextType = AppContext;

class ProcessingPageView extends React.Component {

  render() {
    if (this.props.isLoading) {
      return this.renderLoading();
    }

    if (this.props.cropImage) {
      return this.renderCropper();
    }

    return this.renderPage();
  }

  renderLoading = () => {
    return <Loading withProcessingText />;
  };

  renderCropper = () => {
    return <main className="processing-page processing-page_crop">
      <div className="container">
        <h2 dangerouslySetInnerHTML={{__html: i18n.t("crop_title")}} />
        <p dangerouslySetInnerHTML={{__html: i18n.t("crop_subtitle")}} />

        <div className="crop-container">
          <Cropper
            image={this.props.cropImage.proxiedUrl}
            aspect={this.props.cropState.aspect}
            crop={this.props.cropState}
            zoom={this.props.cropState.zoom}
            zoomSpeed={0.1}
            minZoom={1}
            maxZoom={2}
            showGrid={false}
            onCropChange={this.props.onCropAreaChange}
            onCropComplete={this.props.onCropAreaChangeComplete}
            onZoomChange={this.props.onCropZoomChange} />
          <button onClick={this.props.onCropConfirm}>
            <span dangerouslySetInnerHTML={{__html: i18n.t("btn_continue")}} />
            <SvgSprite viewBox="0 0 16 16" icon="icon-arrow" />
          </button>
        </div>

        <div className="btns-container">
          {/* <button onClick={this.props.onCropCancel}>Cancel</button> */}
          <button onClick={() => this.props.onCropAspectChange(cropAspect.portrait)}>
            <SvgSprite viewBox="0 0 18 24" icon="icon-portrait" />
          </button>
          <button onClick={() => this.props.onCropAspectChange(cropAspect.square)}>
            <SvgSprite viewBox="0 0 18 18" icon="icon-sqaure" />
          </button>
          <button onClick={() => this.props.onCropAspectChange(cropAspect.landscape)}>
            <SvgSprite viewBox="0 0 24 18" icon="icon-landscape" />
          </button>
        </div>
      </div>
    </main>;
  };

  renderPage = () => {
    // если в поле запроса указано хоть что-то
    // если есть подсказки (подсказки есть только при фокусе на поле)
    // если есть результат поиска
    const isSearchStateView = this.props.term.length > 0
      || this.props.termSuggestions.length > 0
      || (this.props.termTask && this.props.termTask.isProcessed);

    return <main className={"processing-page processing-page_step2" + " " + (isSearchStateView ? "processing-page-open" : "")}>
      <div className="container">
        <h2 className="step-title"
            dangerouslySetInnerHTML={{__html: i18n.t("step2_title")}} />
        <p className="step-text" dangerouslySetInnerHTML={{__html: i18n.t("step2_text")}} />

        <form className="form" onSubmit={this.props.onTermSubmit}>
          <h3 className="form-title"
              dangerouslySetInnerHTML={{__html: i18n.t("form_title")}} />

          <div className="form-container">
            <div className="form-input-container">
              <input
                type="text"
                required
                placeholder="Search…"
                className="form-area"
                value={this.props.term}
                onFocus={this.props.onTermFieldFocus}
                onBlur={this.props.onTermFieldBlur}
                onChange={this.props.onTermFieldChange} />
              <button
                className="form-btn-cancel"
                type="button"
                hidden={!isSearchStateView}
                onClick={this.props.onTermCancelButtonClick}>
                <SvgSprite viewBox="0 0 9 9" icon="icon-close" />
              </button>
            </div>
            <button
              className="form-btn-search"
              type="button"
              hidden={!isSearchStateView}
              dangerouslySetInnerHTML={{__html: i18n.t("btn_search")}} />
          </div>
        </form>

        <div className="select-device-container" hidden={isSearchStateView}>
          <span>...or</span>
          <FileChooseButton
            tab="celebs"
            className="btn-select"
            children={i18n.t("btn_choose_device_foto")}
            onFileSelected={this.props.onSecondFileSelected} />
        </div>

        <div className="suggestion-container" hidden={this.props.termSuggestions.length === 0}>
          {this.props.termSuggestions.map((suggestion) => <span
            className="suggestion-item"
            key={suggestion.term}
            onClick={() => this.props.onTermSuggestionClick(suggestion)}>
              {suggestion.term}
            </span>)}
        </div>

        {this.props.termTask && this.props.termTask.isPending && <p className="fetch-message">
          Fetching images. Please wait...
        </p>}

        {this.props.termTask && this.props.termTask.isFailed && <p className="fetch-message">
          ERROR. Fetching images is failed. Try again later.
        </p>}

        {this.props.termTask && this.props.termTask.isProcessed && <div className="select-container">
          {this.props.termTask.result.map((image) => <div key={image.url} className="select-item">
            <ImageView
              image={image.thumbnail}
              onClick={() => this.props.onImageSelect(image)}
              alt="Result"
              square />
          </div>)}
        </div>}

        <picture>
          <source srcSet={assetUrl("assets/img/bg/top-d.png")} media="(min-width: 780px)" />
          <img className="bg-top" srcSet={assetUrl("assets/img/bg/top.png")} alt="bg" />
        </picture>
        <img className="bg-bottom" src={assetUrl("assets/img/bg/bottom.png")} alt="bg"/>
      </div>
    </main>;
  };
}

function filterSuggestion(value, suggestions) {
  if (value.length === 0) {
    return suggestions;
  }

  value = value.toLowerCase();

  return suggestions.filter((item) => {
    const term = item.term.toLowerCase();

    return term.indexOf(value) > -1
      && term !== value;
  });
}